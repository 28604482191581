import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import Footer from "../Components/footer";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import ReCAPTCHA from "react-google-recaptcha";

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });

  export const Contact = ()=> {
    const [Name, from_name] = useState('');
    const [Email, email] = useState('');
    const [Message, message] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null);
    const [error, setError] = useState('');

    const handleChangeName = (e) => from_name(e.target.value);
    const handleChangeEmail = (e) => email(e.target.value);
  const handleChangeMessage = (e) => message(e.target.value);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      setError('Veuillez vérifier que vous n’êtes pas un robot.');
      return;
    }  

    emailjs.sendForm('service_3hmy8db', 'template_174w96m', e.target, {
      publicKey: '93ffUIr6jk0v-ZjQM',
    })
      .then((result) => {
        console.log(result.text);
        alert('Email envoyé avec succès !');
        from_name('');
        email('');
        message('');
        setCaptchaValue(null);
        setError('');
      }, (error) => {
        console.log(error.text);
        alert('Une erreur est survenue, veuillez réessayer.');
      });
  };
  const { t } = useTranslation();




  
  return (

<div>
<div class="bradcam_area bradcam_bg_1">
<div class="container">
<div class="row">
<div class="col-xl-12">
<div class="bradcam_text text-center">
<h3 datal-i18n="contact">Contact Us</h3>
</div>
</div>
</div>
</div>
</div>


<section class="contact-section">
<div class="container">

<div class="row">
<div class="col-12">
<h2 class="contact-title" datal-i18n="contacttitle">{t('Entrer en contact')}</h2>
</div>
<div class="col-lg-8">
<form onSubmit={handleSubmit}>

<div class="row">
<div class="col-12">
<div class="form-group">
<textarea class="form-control w-100" name="message" value={Message} onChange={handleChangeMessage} id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'" placeholder=" message" required></textarea>
</div>
</div>
<div class="col-sm-6">
<div class="form-group">
<input  class="form-control valid"name="from_name" type="text"value={Name} onChange={handleChangeName}   placeholder={t('name')}required/>
</div>
</div>
<div class="col-sm-6">
<div class="form-group">
<input  class="form-control valid"  value={Email} onChange={handleChangeEmail} name="email"from_namez type="text"  placeholder="Email" required/>
</div>
</div>
<div class="col-12">
<div class="form-group">
<input class="form-control"  name="subject" type="text"  placeholder={t('subject')}/>
</div>
</div>
</div>

<div>
<ReCAPTCHA
          sitekey="6LfK0CsqAAAAAGPah-SX8awOGZg6ABOwjyQahPra"
          onChange={handleCaptchaChange}
        />


</div>
{error && <p style={{ color: 'red' }}>{error}</p>}
<div class="form-group mt-3">
<button type="submit" name="send"class="button button-contactForm boxed-btn">{t('send')}</button>
</div>
</form>
</div>


<div class="col-lg-3 offset-lg-1">
<div class="media contact-info">
<span class="contact-info__icon"><i class="ti-home"></i></span>
<div class="media-body">
<h3>01 RUE HEDI NOUIRA IMMB JUINI 55 2040 RADES SALINE</h3>

</div>
</div>
<div class="media contact-info">
<span class="contact-info__icon"><i class="ti-tablet"></i></span>
<div class="media-body">
<a href="tel:+21671449033">
                            <i className="fa fa-phone"></i> (+216) 71 449 033
                          </a>

</div>
</div>
<div class="media contact-info">
<span class="contact-info__icon"><i class="ti-email"></i></span>
<div class="media-body">
<h3>
  <a href="mailto:contact@itts.com.tn?subject=Bonjour%20World&body=This%20is%20the%20email%20body" class="__cf_email__" data-cfemail="">contact@itts.com.tn</a></h3>
<p>Send us your query anytime!</p>
</div>
</div>
</div>
</div>
</div>
</section>

<Footer/>
</div>


  );
}
export default Contact;