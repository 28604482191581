
import React from "react";
import service1 from'../img/service/1.png';
import service2 from'../img/service/2.png';
import service3 from'../img/service/3.png';
import service4 from'../img/service/7.png';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });
function Service(){
 



    const { t } = useTranslation();
    return(
<div>
<div class="service_area">
<div class="container">
<div class="row">
<div class="col-xl-12">
<div class="section_title mb-50 text-center">
<h3 >
   {t('NosServices')}
</h3>
</div>

</div>
</div>
<div class="row">
<div class="col-xl-12">
<div class="service_active owl-carousel">
<div class="single_service">
<a href="/services?service=fret-maritime" style={{ textDecoration: 'none', color: 'inherit' }}>
<div class="thumb">
<a href="/service#v-pills-home"><img src={service1} /></a>
</div>
<div class="service_info">
<h3 datal-i18n="titleservice1">{t('Fret maritime')}</h3>
<p datal-i18n="descriptionservice1">{t('desc1service1')}
</p>
</div></a>
</div>

<div className="single_service">
    <a href="/services?service=terrestre" style={{ textDecoration: 'none', color: 'inherit' }}>
        <div className="thumb">
            <img src={service2} alt="Service Terrestre" />
        </div>
        <div className="service_info">
            <h3 datal-i18n="titleservice2">{t('terrestre')}</h3>
            <p datal-i18n="descriptionservice2">{t('desc1service2')}</p>
        </div>
    </a>
</div>

<div class="single_service">
<a href="/services?service=fret-aerien" style={{ textDecoration: 'none', color: 'inherit' }}>
<div class="thumb">
<img src={service3} />
</div>
<div class="service_info">
<h3 datal-i18n="titleservice3">{t('Fret Aerien')}</h3>
<p datal-i18n="descriptionservice3">{t('desc1service3')}</p>
</div></a>
</div>
<div class="single_service">
<a href="/services?service=douane" style={{ textDecoration: 'none', color: 'inherit' }}>
<div class="thumb">
<a href="/service"><img src={service4}/></a>
</div>
<div class="service_info">
<h3 datal-i18n="titleservice4">{t('Dédouanement')}</h3>
<p datal-i18n="descriptionservice4">{t('descriptionservice4')}</p>
</div></a>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
    
     )

}
export default Service;
