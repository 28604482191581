import React, {useState} from "react";

import airplane from '../img/svg_icon/airplane.png';
import live from '../img/svg_icon/live.png';
import world from '../img/svg_icon/world.png';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });
function Transportaion(){
    const { t } = useTranslation();
    return(
<div>






<div class="transportaion_area">
<div class="container">
<div class="row">

<div class="col-xl-4 col-lg-4 col-md-6">
<div class="single_transport">
<div class="icon">
<img src={live} />
</div>
<h3> {t('titlequalite1')}</h3>
<p > {t('descriptionQualite1')}</p>
</div>
</div>
<div class="col-xl-4 col-lg-4 col-md-6">
<div class="single_transport">
<div class="icon">
<img src={airplane} alt="airplane" />
</div>
<h3 >{t('titleEfficacite')}</h3>
<p >{t('descEfficacite')}</p>
</div>
</div>
<div class="col-xl-4 col-lg-4 col-md-6">
<div class="single_transport">
<div class="icon">
<img src={world} alt="" />
</div>
<h3 datal-i18n="titledevise">{t('titledevise')}</h3>
<p atal-i18n="descdevise">{t('descdevise')}</p>
</div>
</div>
</div>
</div>
</div>
</div>
    
     )

}
export default Transportaion;