import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Assurez-vous que react-router-dom est installé
import Footer from "../Components/footer";
import './services.css';

import service_details from '../img/service/service_details.png';
import service3_details from '../img/service/service3_details.png';
import service2_details from "../img/service/service2_details.png";
import service4_details from "../img/service/service4_details.png";
import { useTranslation } from "react-i18next";

function Services() {
    const { t } = useTranslation();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("home"); // Onglet par défaut

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const service = params.get("service");
        console.log("Service sélectionné :", service);
        if (service === "terrestre") {
            setActiveTab("profile");
        } else if (service === "fret-aerien") {
            setActiveTab("messages");
        } else if (service === "douane") {
            setActiveTab("douane");
        } else {
            setActiveTab("home"); // Par défaut
        }
    }, [location]);

    return (
        <div>
            <div className="bradcam_area bradcam_area2 bradcam_bg_2">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="bradcam_text text-center">
                                <h3>{t('NosServices')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="service_details_area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="service_details_left">
                                <div className="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a className={activeTab === "home" ? 'active' : ''} href="?service=fret-maritime">{t('Fret maritime')}</a>
                                    <a className={activeTab === "profile" ? 'active' : ''} href="?service=terrestre">{t('Terrestre')}</a>
                                    <a className={activeTab === "messages" ? 'active' : ''} href="?service=fret-aerien">{t('Fret Aerien')}</a>
                                    <a className={activeTab === "douane" ? 'active' : ''} href="?service=douane">{t('Dédouanement')}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="tab-content" id="v-pills-tabContent">
                            <div className={`tab-pane fade ${activeTab === "home" ? "show active" : ""}`} id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="service_details_info">
                                        <h3>{t('Fret maritime')}</h3>
                                        <p>{t('desc1service1')}</p>
                                        <p>{t('desc2service1')}</p>
                                        <p>{t('desc3service1')}</p>
                                        <p>{t('desc4service1')}</p>
                                        <p>{t('desc5service1')}</p>
                                        <p>{t('desc6service1')}</p>
                                        <p>{t('desc7service1')}</p>
                                        <p>{t('desc8service1')}</p>
                                        <p>{t('desc9service1')}</p>
                                    </div>
                                    <div className="service_thumb">
                                        <img src={service_details} alt="" />
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${activeTab === "profile" ? "show active" : ""}`} id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <div className="service_details_info">
                                        <h3>{t('terrestre')}</h3>
                                        <p>{t('desc1service2')}</p>
                                        <p>{t('desc2service2')}</p>
                                        <p>{t('desc3service2')}</p>
                                    </div>
                                    <div className="service_thumb">
                                        <img src={service2_details} alt="" />
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${activeTab === "messages" ? "show active" : ""}`} id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className="service_details_info">
                                        <h3>{t('Fret Aerien')}</h3>
                                        <p>{t('desc1service3')}</p>
                                        <p>{t('desc2service3')}</p>
                                        <p>{t('desc3service3')}</p>
                                        <ul>
                                            <li>{t('desc4service3')}</li>
                                            <li>{t('desc5service3')}</li>
                                            <li>{t('desc6service3')}</li>
                                            <li>{t('desc7service3')}</li>
                                            <li>{t('desc8service3')}</li>
                                        </ul>
                                    </div>
                                    <div className="service_thumb">
                                        <img src={service3_details} alt="" />
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${activeTab === "douane" ? "show active" : ""}`} id="v-pills-douane" role="tabpanel" aria-labelledby="v-pills-douane-tab">
                                    <div className="service_details_info">
                                        <h3>{t('Dédouanement')}</h3>
                                        <p>{t('desc1service4')}</p>
                                        <p>{t('desc2service4')}</p>
                                        <p>{t('desc3service4')}</p>
                                        <p>{t('desc4service4')}</p>
                                        <p>{t('desc5service4')}</p>
                                        <p>{t('desc6service4')}</p>
                                        <p>{t('desc7service4')}</p>
                                        <p>{t('desc8service4')}</p>
                                        <p>{t('desc9service4')}</p>
                                    </div>
                                    <div className="service_thumb">
                                        <img src={service4_details} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Services;
